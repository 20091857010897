<template>
  <div>
    <div>
      <b-modal
        id="modal-form"
        size="xl"
        title="Tambah Dokumen"
        @hidden="modalOnHide"
      >
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- check date -->
          <!-- <b-form-group id="input-group-date">
            <label for="input-email"
              >Tanggal: <em class="text-muted">opsional</em></label
            >
            <b-form-datepicker
              id="input-date"
              locale="id"
              v-model="form.check_date"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.check_date }}</small>
          </b-form-group> -->

          <div class="row">
            <div class="col-md-6">
              <!-- Patient Select -->
              <b-form-group
                id="input-group-patient"
                label="Pilih Pasien:"
                label-for="input-patient"
              >
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="display.patient_name"
                  placeholder="Pilih Pasien"
                  readonly
                  @click="$bvModal.show('modal-patient')"
                >
                </b-form-input>
                <small class="text-danger">{{ error.patient_id }}</small>
              </b-form-group>
            </div>

            <!-- <div class="col-md-6 pb-1">
              <b-form-group id="input-group-blood-type">
                <label for="input-email"
                  >Format File: <em class="text-muted">opsional</em></label
                >
                <b-form-select
                  id="input-blood-type"
                  v-model="form.format"
                  :options="formats"
                  required
                ></b-form-select>
                <small class="text-danger">{{ error.format }}</small>
              </b-form-group>
            </div> -->

            <div class="col-md-6">
              <b-form-group
                id="input-group-title"
                label="Judul:"
                label-for="input-title"
              >
                <b-form-input
                  id="input-title"
                  v-model="form.title"
                  placeholder="Judul Dokumen"
                ></b-form-input>
                <small class="text-danger">{{ error.title }}</small>
              </b-form-group>
            </div>
          </div>

          <!-- description -->
          <b-form-group id="input-group-case">
            <label for="input-email"
              >Deskripsi: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder=""
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <div class="row justify-content-center">
            <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-6"> -->
            <div class="col-md-12">
              <button
                class="btn btn-rounded btn-outline-purple shadow btn-block btn-upload-csv"
                @click="$refs.file.$refs.input.click()"
                type="button"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                >
                  <div class="w-100">
                    <h1 v-if="typeFile == ''">
                      <span class="fas fa-fw fa-lg fa-plus menu-icon"></span>
                    </h1>
                    <h1 v-if="typeFile != ''">
                      <span class="fas fa-fw fa-lg fa-plus menu-icon"></span>
                    </h1>
                    <small class="text-wrap">Upload file</small>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <div class="row" v-if="itemsTable != {}">
            <div class="col-md-12">
              <span>Daftar File :</span>
            </div>
            <div
              class="col-md-3"
              v-for="(val, index) in itemsTable"
              :key="index"
            >
              <div class="card card-rounded">
                <div class="card-body">
                  <div class="row">
                    <span class="fas fa-fw fa-lg fa-file menu-icon pr-2"></span>
                    <span>{{ val.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- File Image -->

          <b-form-file
            multiple
            placeholder="Upload Dokumen"
            drop-placeholder="Drop file here..."
            @change="upload"
            ref="file"
            style="visibility: hidden"
          ></b-form-file>
          <small class="text-danger">{{ error.file }}</small>
        </b-form>
        <template #modal-footer>
          <b-button size="md" variant="primary" @click="modalOnSubmit">
            Unggah
          </b-button>
        </template>
      </b-modal>
    </div>
    <Modal @chosenPatient="setPatient" @submitedPatient="setPatient" />
  </div>
</template>

<script>
import Modal from "@/component/general/ModalPatient.vue";
import module from "@/core/modules/CrudModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    patientData: Object,
  },
  components: {
    Treeselect,
    Modal,
  },

  data() {
    return {
      // file type
      typeFile: "",
      // Form
      form: {
        patient_id: "",
        // format: "",
        title: "",
        description: "",
        title: "",
        check_date: "",
      },
      patient_id: "",
      // Error
      error: {
        patient_id: "",
        format: "",
        description: "",
        title: "",
        files: "",
        check_date: "",
      },
      itemsTable: {},
      // Options
      patients: [],
      formats: [
        { text: "Pdf", value: "pdf" },
        { text: "Docx", value: "docx" },
        { text: "Xls", value: "xls" },
        { text: "Jpg", value: "jpg" },
        { text: "Jpeg", value: "jpeg" },
        { text: "Png", value: "png" },
        { text: "Lainnya", value: "other" },
      ],
      // data
      formData: new FormData(),
      // display
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },

      flagUploaded: false,
    };
  },

  methods: {
    setPatient(value) {
      this.form.patient_id = value.id;
      this.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },

    async getPatientsOption() {
      let response = await module.setTreeSelect("patients");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.patients = response.data;
        this.patients.unshift({
          label: "Pilih Pasien",
          id: "",
          isDisabled: true,
        });
      }
    },

    upload(event) {
      this.itemsTable = event.target.files;
      // Object.keys(event.target.files).forEach((el) => {});

      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("files[]", event.target.files[a]);
      }

      this.flagUploaded = true;
    },

    async formOnSubmit() {
      if (this.flagUploaded) {
        for (const [key, value] of Object.entries(this.form)) {
          this.formData.append(key, value);
        }

        // Make Request
        let response = await module.submit(this.formData, "documents");
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          this.display.patient_name = "";
          this.itemsTable = {};
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$refs.file.value = null;
          this.formData = new FormData();
          this.flagUploaded = false;
          this.$bvModal.hide("modal-form");
        }

        this.$emit("refreshDocuments", this.patient_id);
        this.patient_id = "";
        // this.$parent.getDocuments();
      } else {
        Swal.fire(
          "Perhatian",
          "Belum ada file terpilih untuk diunggah, silahkan pilih file terlebih dahulu",
          "warning"
        );
      }
      // this.$parent.list()
    },

    modalOnSubmit(evt) {
      evt.preventDefault();
      this.formOnSubmit();
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error);
      this.form = validation.clearForm(this.form);
      this.$bvModal.hide("modal-form");
    },
  },

  mounted() {
    this.getPatientsOption();
    this.form.patient_id = this.patientData.id;
    this.patient_id = this.patientData.id;
    this.display.patient_name = this.patientData.name;
  },

  watch: {
    patientData: {
      handler(newVal) {
        if (newVal) {
          this.form.patient_id = newVal.id;
          this.patient_id = newVal.id;
          this.display.patient_name = newVal.name;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.btn-outline-purple {
  border: 1.28px solid !important;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.card-rounded {
  border: 1.28px solid !important;
  border-radius: 15px;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.btn-outline-purple:hover {
  border-color: #305b8f !important;
  background: #305b8f;
  color: white !important;
}

.btn-rounded {
  border-radius: 15px;
}
</style>
