<template>
  <div>
    <Table
      :items="items"
      :fields="fields"
      :hasPatient="true"
      :perPage="perPage"
      :currentPage="currentPage"
      :totalRows="totalRows"
      @filterByDoctor="filterByDoctor"
      @btnDownloadOnClick="btnDownloadNowOnClick"
      @btnDetailOnClick="btnDetailOnClick"
      @pageOnClick="pageOnClick"
      @filterByPeriode="filterByPeriode"
      @resetFilter="resetFilter"
    />

    <b-modal id="modal-print" hide-footer size="xl" title="Preview Resep">
      <div class="row">
        <div class="col-md-8">
          <Print
            :data="data"
            height="1280px"
            :setting="setting"
            :doctor="doctor"
            :patient="patient"
            :patientAge="patientAge"
            :splitMedicine="splitMedicine"
            :strMedicine="strMedicine"
            :displayMedicine="displayMedicine"
          />
        </div>
        <div class="col-md-4">
          <b-button squared size="sm" variant="success" @click="btnPrintOnClick"
            >Print</b-button
          >
          <b-button
            squared
            :class="'d-block mt-2'"
            size="sm"
            variant="info"
            @click="btnDownloadOnClick"
            >Download</b-button
          >
        </div>
      </div>
    </b-modal>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`struk-resep-${data.patient_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          height="980px"
          :setting="setting"
          :doctor="doctor"
          :patient="patient"
          :patientAge="patientAge"
          :splitMedicine="splitMedicine"
          :strMedicine="strMedicine"
          :displayMedicine="displayMedicine"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Print from "@/component/prescriptions/Print.vue";
import Table from "@/component/prescriptions/Table.vue";
import VueHtml2pdf from "vue-html2pdf";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  name: "PrescriptionHistory",

  components: {
    Table,
    VueHtml2pdf,
    Print,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "display_medicine",
          label: "Obat",
          sortable: true,
        },
        { key: "actions", label: "Tombol", thClass: "th-action-prescription" },
      ],
      // Table Items
      items: [],
      // Detail Data
      data: {},
      doctor: {},
      patient: {},
      patientAge: "",
      splitMedicine: "",
      strMedicine: [],
      setting: {},
      displayMedicine: [],
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    async getSetting() {
      this.setting = await module.get("website-settings/get-default");
      if (this.setting == null) {
        this.setting = {
          address:
            "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          emergency: "(021) 3909333",
        };
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.id;
      }
      let filterParams = `&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        `prescriptions/get-by-patient/${this.$route.params.id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.items = response.data;
      this.totalRows = pagination.total;
    },

    async getData(id) {
      this.data = await module.get(`prescriptions/${id}`);
      this.displayMedicine = JSON.parse(this.data.medicine);
      this.splitMedicine = this.data.medicine;
      this.strMedicine = this.splitMedicine.replace(
        'border="1">',
        'border="0">'
      );
      this.doctor = await module.list("doctors/" + this.data.doctor_id);
      this.patient = await module.list("patients/" + this.data.patient_id);
      this.getAge(this.patient.birt_date);
    },

    async getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.patientAge = age;
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    filterByDoctor(sended) {
      this.filter.doctor_id = sended.doctor_id;
      this.pagination();
    },

    filterByPeriode(sended) {
      this.filter.start_date = sended.start_date;
      this.filter.end_date = sended.end_date;
      this.pagination();
    },
    resetFilter(sended) {
      this.filter.start_date = "";
      this.filter.end_date = "";
      if (this.currentUser.user_type_id != 1) {
        this.filter.doctor_id = "";
      }
      this.pagination();
    },

    btnDetailOnClick(id) {
      this.getData(id);
    },

    async btnDownloadNowOnClick(id) {
      await this.getData(id);
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },
  },

  mounted() {
    // Get Table Data
    this.getSetting();
    this.pagination();
  },
};
</script>

<style>
</style>