<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah Daftar<b> Rekam Medis Pasien</b> yang ada di
        {{ clinic_name }}
      </div>
      <b-button squared variant="success" @click="handleAdd">Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <!-- <div class="col-md-4"> -->

        <b-alert
          v-if="$route.query.appointment_id && $route.query.doctor_id"
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Perjanjian
            <strong>{{
              doctors[doctors.findIndex((x) => x.id == $route.query.doctor_id)]
                .label
            }}</strong>
          </div>
          <b-button
            squared
            variant="success"
            @click="
              $router.push(`/appointment/detail/${$route.query.appointment_id}`)
            "
            >Detail</b-button
          >
        </b-alert>

        <!-- add medical record -->
        <b-modal
          id="modal-add"
          size="xl"
          title="Tambah Rekam Medis"
          hide-footer
          @hidden="handleCancel('add')"
        >
          <!-- <Card v-bind:title="'Tambah Rekam Medis'" v-if="page == 'add'"> -->
          <!-- <template v-slot:body> -->
          <div>
            <b-form
              v-if="show"
              @submit.stop.prevent="formAdd"
              enctype="multipart/form-data"
            >
              <b-form-group id="input-group-date">
                <label for="input-date">Tanggal:</label>

                <div class="row">
                  <div class="col-md-6">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-datepicker
                          locale="id"
                          placeholder="Tanggal"
                          id="input-date"
                          v-model="date"
                          button-only
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-prepend>
                      <b-form-input
                        id="example-input"
                        v-model="date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        @input="formaterDate"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-md-6">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-timepicker
                          v-model="time"
                          locale="id"
                          placeholder="Waktu"
                          button-only
                        ></b-form-timepicker>
                      </b-input-group-prepend>

                      <b-form-input
                        id="example-input"
                        v-model="time"
                        type="text"
                        placeholder="HH:MM:SS"
                        @input="formaterTime"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <small class="text-danger">{{ error.date }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-trigger-modal-patient"
                label="Pilih Pasien :"
                label-for="input-trigger-modal-patient"
              >
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="display.patient_name"
                  placeholder="Pilih Pasien"
                  readonly
                  @click="$bvModal.show('modal-patient')"
                >
                </b-form-input>
                <small class="text-danger">{{ error.patient_id }}</small>
              </b-form-group>

              <!-- sub patient Select -->
              <b-form-group
                v-if="subPatient.length > 1"
                id="input-group-sub-patient"
                label="Pilih Sub Pasien:"
                label-for="input-sub-patient"
              >
                <treeselect
                  v-model="form.sub_patient_id"
                  placeholder="Pilih Sub Pasien"
                  :options="subPatient"
                />
                <small class="text-danger">{{ error.sub_patient_id }}</small>
              </b-form-group>

              <div class="row px-4">
                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == true && addCollapse == false"
                  @click="addCollapse = true"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya :</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                </div>
                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == true && addCollapse == true"
                  @click="addCollapse = false"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya :</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                </div>

                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == false && addCollapse == false"
                  @click="addCollapse = true"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya:</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                </div>
                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == false && addCollapse == true"
                  @click="addCollapse = false"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya:</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                </div>

                <div
                  class="col-md-12 mb-4 bg-secondary rounded"
                  v-if="addCollapse == true"
                >
                  <b-table
                    :items="itemsRecently"
                    :fields="fieldsRecently"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(title)="data">
                      <span v-html="data.item.list_title"></span>
                    </template>

                    <template #cell(display_date)="data">
                      <span v-if="data.item.time == '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })
                      }}</span>
                      <span v-if="data.item.time != '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</span>
                    </template>
                  </b-table>
                </div>

                <div class="col-md-12 d-flex justify-content-between">
                  <span class="font-weight-bolder">Diagnosa Pasien :</span>
                  <b-button
                    size="sm"
                    variant="primary"
                    class="mr-1"
                    @click="$bvModal.show('modal-patient-x-diagnose')"
                  >
                    <i class="fas fa-plus px-0"></i> Diagnosa
                  </b-button>
                </div>

                <div class="col-md-12 mb-4 bg-secondary rounded">
                  <b-table
                    :items="patientXDiagnose"
                    :fields="fieldsPatientXDiagnose"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(action)="data">
                      <b-button
                        size="sm"
                        class="mr-1 btn-danger"
                        @click="deletePatientXDiagnosa(data.item.id)"
                        v-b-tooltip.hover
                        title="Hapus"
                        placement="bottom"
                      >
                        <i class="fas fa-trash px-0"></i>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>

              <b-form-group id="input-group-title">
                <label for="textarea">Uraian:</label>
                <div class="document-editor">
                  <ckeditor
                    id="input-title"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                    v-model="form.title"
                  ></ckeditor>
                </div>
                <small class="text-danger">{{ error.title }}</small>
              </b-form-group>
              <!-- <b-form-group id="input-group-title">
                  <label for="textarea">Uraian:</label>
                  <b-form-textarea
                    id="input-title"
                    v-model="form.title"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group> -->

              <b-form-group id="input-group-case">
                <label for="textarea"
                  >Instruksi: <em class="text-muted">opsional</em></label
                >
                <div class="document-editor">
                  <ckeditor
                    id="input-title"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                    v-model="form.case"
                  ></ckeditor>
                </div>
                <small class="text-danger">{{ error.case }}</small>
              </b-form-group>

              <!-- <b-form-group id="input-group-case">
                  <label for="textarea">Instruksi: <em class="text-muted">opsional</em></label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.case"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.case }}</small>
                </b-form-group> -->

              <!-- Doctor Select -->
              <b-form-group
                v-if="currentUser.user_type_id != 1"
                id="input-group-doctor"
                label="Pilih Dokter:"
                label-for="input-doctor"
              >
                <treeselect
                  v-model="form.doctor_id"
                  :multiple="true"
                  placeholder="Pilih Dokter"
                  :options="doctors"
                  required
                />
                <small class="text-danger">{{ error.doctor_id }}</small>
              </b-form-group>

              <!-- Input alergic -->
              <b-form-group id="input-group-alergic">
                <label for="input-alergic">Alergi Pasien :</label>
                <b-form-input
                  id="input-alergic"
                  v-model="patient.allergic"
                  disabled
                ></b-form-input>
              </b-form-group>

              <!-- Input Description -->
              <b-form-group id="input-group-description">
                <label for="input-description">Catatan Pasien:</label>
                <b-form-textarea
                  id="input-description"
                  v-model="patient.notes"
                  rows="3"
                  disabled
                ></b-form-textarea>
              </b-form-group>

              <!-- attachment -->
              <b-form-group id="input-group-image">
                <label for="input-image"
                  >File Lampiran: <em class="text-muted">opsional</em></label
                >
                <b-form-file
                  multiple
                  placeholder="Upload File"
                  drop-placeholder="Drop file here..."
                  ref="fileUpload"
                  @change="upload"
                ></b-form-file>
                <small class="text-danger">{{ error.attachment }}</small>
              </b-form-group>

              <div class="row justify-content-end mt-4 mb-3">
                <b-button
                  class="mx-2"
                  variant="secondary"
                  type="button"
                  @click="handleCancel('add')"
                  >Batal</b-button
                >
                <b-button class="mx-2" variant="primary" type="submit"
                  >Simpan</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- </template> -->
          <!-- </Card> -->
        </b-modal>
        <!-- end add medical record -->

        <!-- Edit medical record -->
        <b-modal
          id="modal-edit"
          size="xl"
          title="Ubah Rekam Medis"
          hide-footer
          @hidden="handleCancel('edit')"
        >
          <!-- <Card v-bind:title="'Ubah Rekam Medis'" v-if="page == 'edit'"> -->
          <!-- <template v-slot:body> -->
          <div v-if="dataLoaded == true">
            <b-form
              v-if="show"
              @submit.stop.prevent="formEdit(edit.id)"
              enctype="multipart/form-data"
            >
              <!-- <b-form-group
                  id="input-group-date"
                  label="Tanggal:"
                  label-for="input-date"
                >
                  <b-input-group>
                    <b-form-datepicker
                      locale="id"
                      id="input-date"
                      v-model="date"
                    ></b-form-datepicker>

                    <b-form-timepicker
                      v-model="time"
                      locale="id"
                      placeholder="Waktu"
                      now-button
                      label-now-button="Waktu Sekarang"
                      reset-button
                      minutes-step="5"
                    ></b-form-timepicker>
                  </b-input-group>
                  <small class="text-danger">{{ error.date }}</small>
                </b-form-group> -->

              <b-form-group id="input-group-date">
                <label for="input-date"
                  >Tanggal: <em class="text-muted">opsional</em></label
                >

                <div class="row">
                  <div class="col-md-6">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-datepicker
                          locale="id"
                          placeholder="Tanggal"
                          id="input-date"
                          v-model="date"
                          button-only
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-prepend>
                      <b-form-input
                        id="example-input"
                        v-model="date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        @input="formaterDate"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-md-6">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-timepicker
                          v-model="time"
                          locale="id"
                          placeholder="Waktu"
                          button-only
                        ></b-form-timepicker>
                      </b-input-group-prepend>

                      <b-form-input
                        id="example-input"
                        v-model="time"
                        type="text"
                        placeholder="HH:MM:SS"
                        @input="formaterTime"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <small class="text-danger">{{ error.date }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-trigger-modal-patient"
                label="Pilih Pasien :"
                label-for="input-trigger-modal-patient"
              >
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="display.patient_name"
                  placeholder="Pilih karyawan"
                  readonly
                  @click="$bvModal.show('modal-patient')"
                >
                </b-form-input>
                <small class="text-danger">{{ error.patient_id }}</small>
              </b-form-group>

              <!-- sub patient Select -->
              <b-form-group
                v-if="edit.sub_patient_id != null && subPatient.length != 1"
                id="input-group-sub-patient"
                label="Pilih Sub Pasien:"
                label-for="input-sub-patient"
              >
                <treeselect
                  v-model="edit.sub_patient_id"
                  placeholder="Pilih Sub Pasien"
                  :options="subPatient"
                />
                <small class="text-danger">{{ error.sub_patient_id }}</small>
              </b-form-group>

              <div class="row px-4">
                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == true"
                  @click="addCollapse = false"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya :</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                </div>

                <div
                  class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                  v-if="editCollapse == false"
                  @click="addCollapse = true"
                >
                  <span class="font-weight-bolder"
                    >Riwayat Rekam Medis Sebelumnya :</span
                  >
                  <span class="font-weight-bolder"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                </div>

                <div
                  class="col-md-12 mb-4 bg-secondary rounded"
                  v-if="addCollapse == true"
                >
                  <b-table
                    :items="itemsRecently"
                    :fields="fieldsRecently"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(title)="data">
                      <span v-html="data.item.list_title"></span>
                    </template>

                    <template #cell(display_date)="data">
                      <span v-if="data.item.time == '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })
                      }}</span>
                      <span v-if="data.item.time != '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</span>
                    </template>
                  </b-table>
                </div>

                <div class="col-md-12 d-flex justify-content-between">
                  <span class="font-weight-bolder">Diagnosa Pasien :</span>
                  <b-button
                    size="sm"
                    variant="primary"
                    class="mr-1"
                    @click="$bvModal.show('modal-patient-x-diagnose')"
                  >
                    <i class="fas fa-plus px-0"></i> Diagnosa
                  </b-button>
                </div>

                <div class="col-md-12 mb-4 bg-secondary rounded">
                  <b-table
                    :items="patientXDiagnose"
                    :fields="fieldsPatientXDiagnose"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(action)="data">
                      <b-button
                        size="sm"
                        class="mr-1 btn-danger"
                        @click="deletePatientXDiagnosa(data.item.id)"
                        v-b-tooltip.hover
                        title="Hapus"
                        placement="bottom"
                      >
                        <i class="fas fa-trash px-0"></i>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>

              <b-form-group id="input-group-title">
                <label for="textarea">Uraian:</label>
                <div class="document-editor">
                  <ckeditor
                    id="input-title"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                    v-model="edit.title"
                  ></ckeditor>
                </div>
                <small class="text-danger">{{ error.title }}</small>
              </b-form-group>

              <b-form-group id="input-group-case">
                <label for="textarea"
                  >Instruksi: <em class="text-muted">opsional</em></label
                >
                <div class="document-editor">
                  <ckeditor
                    id="input-title"
                    :editor="editor"
                    :config="editorConfig"
                    @ready="onReady"
                    v-model="edit.case"
                  ></ckeditor>
                </div>
                <small class="text-danger">{{ error.case }}</small>
              </b-form-group>

              <!-- Doctor Select -->
              <b-form-group
                id="input-group-doctor"
                label="Pilih Dokter:"
                label-for="input-doctor"
              >
                <treeselect
                  v-model="edit.doctor_id"
                  :multiple="true"
                  placeholder="Pilih Dokter"
                  :options="doctors"
                  required
                />
                <small class="text-danger">{{ error.doctor_id }}</small>
              </b-form-group>

              <!-- Input alergic -->
              <b-form-group id="input-group-alergic">
                <label for="input-alergic">Alergi Pasien :</label>
                <b-form-input
                  id="input-alergic"
                  v-model="patient.allergic"
                  disabled
                ></b-form-input>
              </b-form-group>

              <!-- Input Description -->
              <b-form-group id="input-group-description">
                <label for="input-description">Catatan Pasien:</label>
                <b-form-textarea
                  id="input-description"
                  v-model="patient.notes"
                  rows="3"
                  disabled
                ></b-form-textarea>
              </b-form-group>

              <!-- attachment -->
              <b-form-group
                id="input-group-image"
                label="File Lampiran:"
                label-for="input-image"
              >
                <b-form-file
                  multiple
                  placeholder="Upload File"
                  drop-placeholder="Drop file here..."
                  @change="upload"
                  ref="fileUpload"
                ></b-form-file>
                <small class="text-danger">{{ error.attachment }}</small>
              </b-form-group>

              <div class="row">
                <div class="col-lg-12">
                  <h6 class="d-block">Lampiran Dokumen:</h6>
                  <!-- <div
                      v-for="(attachments, index) in displayAttachment"
                      :key="index"
                      target="_blank"
                    >
                      <b-card
                        class="m-2"
                        v-if="
                          displayAttachment.length >= 1 &&
                          displayAttachment[0] != ''
                        "
                      >
                        <b-card-text class="d-flex justify-content-between">
                          <h5>{{ edit.attachment_title[index] }}</h5>
                          <h5
                            style="cursor: pointer"
                            @click="deleteAttach(index)"
                          >
                            X
                          </h5>
                        </b-card-text>
                      </b-card>
                    </div> -->

                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="(attachments, index) in displayAttachment"
                      :key="index"
                      target="_blank"
                    >
                      <b-card
                        border-variant="primary"
                        align="center"
                        class="m-2"
                        v-if="
                          displayAttachment.length >= 1 &&
                          displayAttachment[0] != ''
                        "
                      >
                        <b-card-text class="d-flex justify-content-between">
                          <i class="fas fa-file mr-2 d-inline fa-3x"></i>
                          <h5>
                            {{
                              detail.attachment_title[index].replace(/_/g, " ")
                            }}
                          </h5>
                          <h5
                            style="cursor: pointer"
                            @click="deleteAttach(index)"
                          >
                            <i class="fas fa-times text-danger"></i>
                          </h5>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h6 class="d-block">Lampiran Gambar :</h6>
                  <div class="col-lg-12">
                    <div class="row" v-if="displayImage != null">
                      <div
                        class="col-md-4"
                        v-for="(display, index) in displayImage"
                        :key="display"
                      >
                        <b-card border-variant="primary" align="center">
                          <b-card-text>
                            <div class="d-flex justify-content-between">
                              <a
                                :href="displayImage[index]"
                                download="file"
                                target="_blank"
                              >
                                <img
                                  class="image"
                                  :src="displayImage[index]"
                                  style="position: relative !important"
                                />
                              </a>
                              <h5
                                style="cursor: pointer"
                                @click="deleteAttachImage(index)"
                              >
                                <i class="fas fa-times text-danger"></i>
                              </h5>
                            </div>
                          </b-card-text>
                        </b-card>
                      </div>

                      <!-- <div
                          class="col-md-4"
                          v-for="(display, index) in displayImage"
                          :key="display"
                        >
                          <div class="d-flex justify-content-between">
                            <img
                              class="image"
                              :src="displayImage[index]"
                              style="position: relative !important"
                            />

                            <h5
                              style="cursor: pointer"
                              @click="deleteAttachImage(index)"
                            >
                              X
                            </h5>
                          </div>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end mt-4 mb-3">
                <b-button
                  class="mx-2"
                  variant="secondary"
                  type="button"
                  @click="handleCancel('edit')"
                  >Batal</b-button
                >
                <b-button variant="info" @click="page = 'drawing'" class="mx-2">
                  <i class="fas fa-plus mr-1"></i> Coretan Dokter</b-button
                >

                <b-button variant="primary" type="submit">Simpan</b-button>
              </div>
            </b-form>
          </div>
          <!-- </template> -->
          <!-- </Card> -->
        </b-modal>
        <!-- end Edit medical record -->

        <!-- Detail medical record-->
        <b-modal
          id="modal-detail"
          size="xl"
          title="Detail Rekam Medis"
          hide-footer
          @hidden="handleCancel('detail')"
        >
          <!-- <Card v-bind:title="'Detail Rekam Medis'" v-if="page == 'detail'"> -->
          <!-- <template v-slot:body> -->
          <div class="container" v-if="dataLoaded == true">
            <div class="d-flex justify-content-end"></div>
            <table class="table mb-3">
              <tr>
                <td><strong>Tanggal</strong></td>
                <td v-if="detail.time == '00:00'">
                  {{
                    new Date(detail.date).toLocaleString("id-ID", {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })
                  }}
                </td>
                <td v-if="detail.time != '00:00'">
                  {{
                    new Date(detail.date).toLocaleString("id-ID", {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>Nama Pasien</strong></td>
                <td>{{ detail.patient_name }}</td>
              </tr>
              <tr>
                <td><strong>Telepon</strong></td>
                <td>{{ detail.patient_mobile_phone }}</td>
              </tr>
              <tr>
                <td><strong>Alamat</strong></td>
                <td>{{ detail.patient_address }}</td>
              </tr>
              <tr>
                <td><strong>Alergi</strong></td>
                <td>{{ detail.patient_allergy }}</td>
              </tr>
              <tr>
                <td><strong>Catatan</strong></td>
                <td>{{ detail.patient_notes }}</td>
              </tr>
              <tr>
                <td><strong>Uraian </strong></td>
                <td v-html="detail.title"></td>
              </tr>
              <tr>
                <td><strong>Instruksi</strong></td>
                <td v-html="detail.case"></td>
              </tr>
              <tr>
                <td><strong>Dokter</strong></td>
                <td>
                  <ul>
                    <li
                      v-for="(doctor_name, index) in detail.doctor_name"
                      :key="index"
                    >
                      {{ doctor_name }}
                    </li>
                  </ul>
                </td>
              </tr>
            </table>

            <div class="row">
              <div class="col-lg-12">
                <h6 class="d-block">Lampiran Dokumen :</h6>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="(attachments, index) in displayAttachment"
                    :key="index"
                    target="_blank"
                  >
                    <!-- <a :href="displayAttachment[index]" download="file"> -->
                    <b-card
                      border-variant="primary"
                      align="center"
                      class="m-2"
                      @click="openNewWindow(displayAttachment[index])"
                      v-if="
                        displayAttachment.length >= 1 &&
                        displayAttachment[0] != ''
                      "
                    >
                      <b-card-text class="d-flex justify-content-start">
                        <i class="fas fa-file mr-2 d-inline fa-3x"></i>
                        <div class="row">
                          <div class="col-md-12">
                            <h5>
                              {{
                                detail.attachment_title[index].replace(
                                  /_/g,
                                  " "
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                      </b-card-text>
                    </b-card>
                    <!-- </a> -->
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <h6 class="d-block">Lampiran Gambar :</h6>
                <div class="col-lg-12" v-if="displayImage != null">
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="(display, index) in displayImage"
                      :key="display"
                    >
                      <b-card
                        border-variant="primary"
                        align="center"
                        @click="openNewWindow(displayImage[index])"
                      >
                        <b-card-text>
                          <!-- <a
                              :href="displayImage[index]"
                              download="file"
                              target="_blank"
                            > -->
                          <img
                            class="image"
                            :src="displayImage[index]"
                            style="position: relative !important"
                          />
                          <!-- </a> -->
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </template> -->
          <!-- </Card> -->
        </b-modal>
        <!-- Detail medical record-->
      </div>

      <div class="col-md-12" v-if="page != 'drawing'">
        <!-- <div
        class="col-md-8"
        v-if="page != 'drawing'"
      > -->
        <Card v-bind:title="'Semua Rekam Medis'">
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>

              <div class="col-md-3 col-12">
                <b-input-group>
                  <!-- <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template> -->
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien"
                    @keyup="patientOnKeyUp"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                      <b-button squared @click="searchFilter" variant="success"
                        >Cari</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="searchFilter"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <span
                  style="width: 150px; color: #245590 !important"
                  class="font-weight-bold"
                  >{{ recordData }} Baris Data</span
                >
              </div>
            </div>
            <!-- <div class="row mx-2">
              <div class="col-12">
                <span><i class="fas fa-info-circle text-info"></i> Tips</span>  
                <span>

                </span>
              </div>        
            </div>               -->

            <!-- table -->
            <div v-if="dataLoaded == true">
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                responsive="sm"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'actions' ? '20%' : '',
                      width: field.key === 'title' ? '30%' : '',
                    }"
                  />
                </template>

                <template #cell(title)="data">
                  <span v-html="data.item.list_title"></span>
                </template>

                <template #cell(display_date)="data">
                  <span v-if="data.item.time == '00:00'">{{
                    new Date(data.item.date).toLocaleString("id-ID", {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })
                  }}</span>
                  <span v-if="data.item.time != '00:00'">{{
                    new Date(data.item.date).toLocaleString("id-ID", {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}</span>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    @click="handleDetail(data.item.id)"
                    v-b-tooltip.hover
                    title="Detail"
                    placement="bottom"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    @click="
                      handleEdit(data.item.id, data.item.patient_id, data.item)
                    "
                    v-b-tooltip.hover
                    title="Ubah"
                    placement="bottom"
                    v-if="manipulateBtn == true"
                  >
                    <i class="fas fa-edit px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    @click="deleteData(data.item.id)"
                    v-b-tooltip.hover
                    title="Hapus"
                    placement="bottom"
                    v-if="manipulateBtn == true"
                  >
                    <i class="fas fa-trash px-0"></i>
                  </b-button>

                  <b-button
                    @click="handleDrawList(data.item.id)"
                    class="btn bg-primary text-white"
                    >Coretan Dokter</b-button
                  >
                </template>
              </b-table>
              <!-- pagination -->
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-md-12" v-if="page == 'drawing'">
        <Card v-bind:title="'Gambar Dokter'">
          <template v-slot:body>
            <div v-if="dataLoaded == true">
              <div class="container">
                <div class="row">
                  <div class="col-12 mt-2">
                    <VueSignaturePad
                      id="signature"
                      width="100%"
                      height="500px"
                      ref="signaturePad"
                      :options="options"
                    />
                    <span class="text-danger mt-2"
                      >*Setelah Menekan Tombol Simpan, Coretan Akan Langsung
                      Tersimpan</span
                    >
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-3 mt-2">
                    <button
                      class="btn btn-danger mx-1"
                      @click="handleDetail(detail.id)"
                    >
                      Batal
                    </button>
                    <button class="btn btn-secondary mx-1" @click="undo">
                      Undo
                    </button>
                    <button class="btn btn-primary mx-1" @click="save">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <Modal
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
      source="casemanager"
    />
    <!-- <ModalDrawing
      :detail="detail"
      :attachment="attachment"
    /> -->
    <ModalPatientXDiagnose
      :patient_id="page == 'add' ? form.patient_id : edit.patient_id"
      :page="page"
      @updateFormDiagnoseChoosenList="getPatientXDiagnose"
    />
  </div>
</template>

<script>
import Modal from "@/component/general/ModalPatient.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debouncer } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import moment from "moment";
// import ModalDrawing from '@/component/general/ModalDrawing.vue';
import ModalPatientXDiagnose from "@/component/masters/diagnoses/Modal.vue";

export default {
  data() {
    return {
      // collapse
      addCollapse: false,
      editCollapse: false,
      detailPatientXDiagnose: [],
      patientXDiagnose: [],
      fieldsPatientXDiagnose: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      // data
      items: null,
      recordData: 0,
      formData: new FormData(),
      page: "add",
      // patientData: [],
      attachment: "",
      displayImage: [],
      displayAttachment: [],
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
      doctors: [],
      subPatient: [],

      // date
      date: "",
      time: "",

      // other
      dataLoaded: false,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "title",
          label: "Uraian ",
          sortable: true,
        },

        { key: "actions", label: "Aksi" },
      ],
      itemsRecently: [],
      fieldsRecently: [
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "title",
          label: "Uraian ",
          sortable: true,
        },
      ],

      error: {
        title: "",
        case: "",
        // date: "",
        patient_id: "",
        attachment: "",
        doctor_id: "",
      },
      form: {
        title: "",
        case: "",
        // date: "",
        appointment_id: this.$route.query.appointment_id ?? "",
        sub_patient_id: "",
        patient_id: this.$route.query.patient_id
          ? parseInt(this.$route.query.patient_id)
          : "",
        doctor_id: this.$route.query.doctor_id
          ? [this.$route.query.doctor_id]
          : [],
      },
      edit: [],
      detail: [],
      // data
      show: true,

      // draw
      options: {
        penColor: "#000000",
      },
      // access management
      manipulateBtn: false,
      // filter
      filter: {
        name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // patient info data
      patient: {
        allergic: "",
        notes: "",
      },
      // other
      doctorDisabled: true,
      currentUser: getUser(),
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
          options: ["left", "right", "center", "justify"],
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },
  components: {
    Card,
    Treeselect,
    Modal,
    // ModalDrawing,
    ModalPatientXDiagnose,
  },

  methods: {
    async getPatientXDiagnose(patient_id) {
      let route = "patient-x-diagnoses/all";
      let response = await module.paginate(route, `?patient_id=${patient_id}`);
      if (this.page != "detail") {
        this.patientXDiagnose = response.data;
      } else {
        this.detailPatientXDiagnose = response.data;
      }
    },
    async deletePatientXDiagnosa(id) {
      let result = await module.delete("patient-x-diagnoses/" + id);
      if (result) {
        this.getPatientXDiagnose(this.form.patient_id);

        this.$root.$emit(
          "updateModalPatientXDiagnoseFromPage",
          this.form.patient_id
        );
      }
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    // handleDate(date) {
    //   return moment(date).fomat("DD-MM-YYYY");
    // },
    handleAdd(resource = "") {
      this.page = "add";
      this.subPatient = [];
      this.patientXDiagnose = [];

      // remove validate
      this.error.title = "";
      this.error.case = "";
      this.error.patient_id = "";
      this.error.attachment = "";
      this.error.doctor_id = "";

      // this.getPatientsOption();
      this.attachment = [];
      this.date = null;
      this.time = null;
      this.display.patient_name = this.$route.query.patient_name ?? "";

      // console.log("add", this.form, this.display);
      // console.log("display", this.display);
      // console.log("query", this.$route.query);

      if (this.$route.query.hasOwnProperty("patient_id")) {
        // this.form.patient_id = this.$route.query.patient_id;
        this.getSubPatientOption("add");
        this.getPatientXDiagnose(this.form.patient_id);
        this.getRecenly();
      }

      if (resource != "delete") {
        this.$bvModal.show("modal-add");
      }
    },
    handleList() {
      this.list();
    },
    handleEdit(id, patient_id = null, item) {
      this.edit = [];
      this.dataLoad = false;
      this.page = "edit";
      this.patientXDiagnose = [];

      // remove validate
      this.error.title = "";
      this.error.case = "";
      this.error.patient_id = "";
      this.error.attachment = "";
      this.error.doctor_id = "";

      this.getMedicalRecord(id);

      this.form.patient_id = patient_id;
      this.getPatientXDiagnose(patient_id);

      // trigger update data modal
      this.$root.$emit(
        "updateModalPatientXDiagnoseFromPage",
        this.form.patient_id
      );

      // this.getPatientsOption();
      this.attachment = [];
      this.$bvModal.show("modal-edit");
    },
    handleDrawList(id) {
      this.attachment = [];
      this.getMedicalRecord(id);
      this.handleDraw();
    },
    handleDraw() {
      this.page = "drawing";
    },
    handleDetail(id) {
      this.detail = [];
      this.attachment = [];
      this.dataLoad = false;
      this.page = "detail";
      this.getMedicalRecord(id);
      this.getPatientXDiagnose(this.detail.patient_id);
      this.$bvModal.show("modal-detail");
    },
    handleCancel(type) {
      if (type == "add") {
        this.page = "add";
        this.subPatient = [];
        // this.getPatientsOption();
        this.attachment = [];
        this.date = null;
        this.time = null;

        // reset display data patient
        this.patient.allergic = "";
        this.patient.notes = "";

        this.display.patient_name = null;
        this.$bvModal.hide("modal-add");
      } else {
        this.$bvModal.hide(`modal-${type}`);
      }
    },
    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.patient.allergic = value.allergic;
      this.patient.notes = value.notes;

      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctorsOption();
      }
      this.doctorDisabled = false;
      this.$bvModal.hide("modal-patient");
      this.subPatient = [];
      this.getSubPatientOption("add");
      this.getPatientXDiagnose(this.form.patient_id);
      // trigger update data modal
      this.$root.$emit(
        "updateModalPatientXDiagnoseOnSetPatient",
        this.form.patient_id
      );

      this.getRecenly();
    },
    deleteAttach(index) {
      this.displayAttachment.splice(index, 1);
    },
    deleteAttachImage(index) {
      this.displayImage.splice(index, 1);
    },

    patientOnKeyUp() {
      debouncer(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (
          this.filter.name != "" ||
          this.filter.start_date != "" ||
          this.filter.end_date != ""
        ) {
          this.currentPage = 1;
        } else {
          this.currentPage = this.lastCurrentPage;
        }
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.list();
      }, 500);
    },

    async getRecenly() {
      let route = `medical-record/recently`;
      let filterParams = `&patient_id=${this.form.patient_id}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=3${filterParams}`
      );
      this.itemsRecently = response.data;
    },

    async list() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        route = `medical-records/get-by-doctor/${this.currentUser.id}`;
      } else {
        route = "medical-records";
      }
      let filterParams = `&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;
      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
      this.dataLoaded = true;
    },

    async deleteData(id) {
      let result = await module.delete("medical-records/" + id);
      if (result) {
        this.list();
        this.handleAdd("delete");
      }
    },

    async getDoctorsOption() {
      let route;
      if (
        typeof this.edit.patient_id !== "undefined" &&
        this.edit.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.edit.patient_id}`;
      } else if (
        typeof this.form.patient_id !== "undefined" &&
        this.form.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.form.patient_id}`;
      } else {
        route = "doctors";
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getSubPatientOption(type) {
      let route = "";
      if (type == "add") {
        route = "sub-patients/" + this.form.patient_id;
      } else if (type == "edit") {
        route = "sub-patients/" + this.edit.patient_id;
      }

      let response = await module.setTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.subPatient = response.data;
        this.subPatient.unshift({
          label: "Pilih Sub Pasien",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMedicalRecord(id) {
      this.attachment = [];
      this.displayImage = [];
      this.displayAttachment = [];
      let response = await module.get("medical-records/" + id);

      if (response) {
        if (response.attachment != null && response.attachment != "") {
          let attach = response.attachment.split(",");

          for (let a = 0; a < attach.length; a++) {
            let dataAttach = attach[a].split(".");

            if (dataAttach.length > 1) {
              if (dataAttach[dataAttach.length - 1] === "jpg") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "png") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "jpeg") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "bmp") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "pdf") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "xlsx") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "csv") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "docx") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "xls") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "zip") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "rar") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "txt") {
                this.displayAttachment.push(attach[a]);
              } else {
                this.displayImage.push(attach[a]);
              }
            } else {
              this.displayImage.push(attach[a]);
            }
          }

          this.attachment = attach;
        }
        this.edit = response;
        this.detail = response;
        if (this.detail.time == "00:00") {
          this.detail.display_date = this.detail.dateCondition;
        }

        this.dataLoad = true;
        let dateTime = response.date.split(" ");

        this.date = dateTime[0];
        this.time = dateTime[1];
        this.display.patient_name = `${response.patient_name} (${response.patient_unique_id})`;

        this.subPatient = [];
        this.getSubPatientOption("edit");
      }
    },

    async getDocument(id) {
      this.list = [];
      this.attachments = [];
      this.fileNameDetail = [];
      let listData = await module.get(
        "documents/" + id + "/" + this.allDayDetail
      );
      this.list = listData[0];

      this.attachments = listData[0].files.split(",");

      let a;
      for (a = 0; a <= this.attachments.length - 1; a++) {
        let data = this.attachments[a].split("#");
        this.fileNameDetail.push(data[2].split("_").join(" "));
      }

      this.dataLoaded = true;
    },

    // async getPatientsOption() {
    //   let route
    //   if (this.currentUser.user_type_id != 1) {
    //     route = 'patients'
    //   } else {
    //     route = `patients/get-by-doctor/${this.currentUser.user_id}`
    //   }
    //   let response = await module.setTreeSelect(route);
    //   // Success
    //   if (response.state == "success") {
    //     // Set Option Select
    //     this.dataLoad = true;
    //     this.patientData = response.data;
    //     this.patientData.unshift({
    //       label: "Pilih Pasien",
    //       id: "",
    //       isDisabled: true,
    //     });
    //   }
    // },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }
    },

    async formAdd() {
      console.log(this.form, "addd");
      if (this.currentUser.user_type_id == 1) {
        this.form.doctor_id = [this.currentUser.id];
      }
      if (this.date != null && this.date != "") {
        this.form.date = this.date + " " + this.time;
      }
      for (const [key, val] of Object.entries(this.form)) {
        if (key == "doctor_id") {
          let value = JSON.stringify(val);
          this.formData.append(key, value);
        } else {
          this.formData.append(key, val);
        }
      }
      // Make Request
      let response = await module.submit(this.formData, "medical-records");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        console.log("form", this.form);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.date = "";
        this.time = "";
        // reset form
        this.form.title = "";
        this.form.case = "";
        this.form.appointment_id = "";
        this.form.patient_id = "";
        this.form.sub_patient_id = "";
        this.form.doctor_id = [];
        this.$refs.fileUpload.value = null;
        this.$bvModal.hide("modal-add");

        this.error = validation.clearForm(this.error);
        this.handleList();
        this.formData = new FormData();
        this.display.patient_name = "";
      }
    },

    async formEdit(id) {
      // implode attachment
      let dataPush = [];
      for (let a = 0; a < this.displayImage.length; a++) {
        dataPush.push(this.displayImage[a]);
      }
      for (let b = 0; b < this.displayAttachment.length; b++) {
        dataPush.push(this.displayAttachment[b]);
      }

      this.attachment = dataPush;

      if (this.date != "" && this.time != "") {
        this.edit.date = this.date + " " + this.time;
      }
      let attachments = this.attachment.join();

      this.formData.append("_method", "PUT");
      this.formData.append("attachment_list", attachments);

      for (const [key, value] of Object.entries(this.edit)) {
        let val = value;
        if (key == "doctor_id") {
          val = JSON.stringify(val);
        }
        this.formData.append(key, val);
      }

      // Make Request
      let response = await module.submit(
        this.formData,
        "medical-records/" + id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        console.log("form", this.form);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.edit = [];
        this.attachment = [];
        // reset input file
        this.$refs.fileUpload.value = null;
        this.$bvModal.hide("modal-edit");

        this.handleList();
        // this.handleAdd();
        this.formData = new FormData();
      }
    },

    // draw
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    async save() {
      // implode attachment
      let dataPush = [];
      for (let a = 0; a < this.displayImage.length; a++) {
        dataPush.push(this.displayImage[a]);
      }
      for (let b = 0; b < this.displayAttachment.length; b++) {
        dataPush.push(this.displayAttachment[b]);
      }

      this.attachment = dataPush;

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // this.attachment = data
      this.formData.append("attachment[]", this.dataURItoBlob(data));

      if (isEmpty == false) {
        let attachments;

        if (this.attachment.length > 0) {
          attachments = this.attachment;
        } else {
          attachments = this.attachment.join();
        }

        this.formData.append("_method", "PUT");
        this.formData.append("attachment_list", attachments);
        for (const [key, value] of Object.entries(this.edit)) {
          let val = value;
          if (key == "doctor_id") {
            val = JSON.stringify(val);
          }
          this.formData.append(key, val);
        }
        // Make Request
        let response = await module.submit(
          this.formData,
          "medical-records/" + this.edit.id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          //  this.$bvModal.hide("modal-drawing");
          this.handleEdit(this.edit.id);
          this.formData = new FormData();
        }
      } else {
        Swal.fire(
          "Gagal Menyimpan Gambar",
          "Area Gambar Masih Kosong",
          "error"
        );
      }
    },

    // async getMedicalRecord() {
    //   let response = await module.get("medical-records/" + this.detail.id);

    //   if (response) {
    //     let attach = response.attachment.split(",");
    //     this.attachment = attach;
    //   }
    // },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3006") {
          this.manipulateBtn = true;
        }
      }
    },

    openNewWindow(url) {
      window.open(url);
    },

    searchFilter() {
      if (
        this.filter.name != "" ||
        this.filter.start_date != "" ||
        this.filter.end_date != ""
      ) {
        this.currentPage = 1;
      } else {
        this.currentPage = this.lastCurrentPage;
      }
      this.list();
    },
    // filter
    resetFilter() {
      // this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      // this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.filter = validation.clearForm(this.filter);
      this.btnActiveOnClick(this.lastPeriodType);
      this.list();
    },

    // pagination
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.list();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }

      if (type !== "periode") {
        if (
          this.filter.name != "" ||
          this.filter.start_date != "" ||
          this.filter.end_date != ""
        ) {
          this.currentPage = 1;
        } else {
          this.currentPage = this.lastCurrentPage;
        }
        this.list();
      }
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.date = this.date + "-";
      }
      if (val.length == 7) {
        this.date = this.date + "-";
      }
    },

    formaterTime(val) {
      if (val.length == 2) {
        this.time = this.time + ":";
      }
      if (val.length == 5) {
        this.time = this.time + ":";
      }
    },
  },

  watch: {
    "form.patient_id": function (newVal) {
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = [];
        this.getDoctorsOption();
      }
    },
    "edit.patient_id": function (newVal) {
      if (this.currentUser.user_type_id != 1) {
        this.getDoctorsOption();
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Rekam Medis" },
    ]);
    this.list();
    // Get Doctors
    this.getDoctorsOption();
    if (this.$route.query.hasOwnProperty("resource") && this.$route.query.resource == "timeline") {  
      this.handleDetail(this.$route.query.id);
    }
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.id;
    }
    this.setActiveMenu();
  },
};
</script>

<style scoped>
/* CK Editor */

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.modal-dialog.modal-sm {
  max-width: 1450px;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
